import React from 'react';
import MenuItem from './MenuItem';
import PropTypes from "prop-types";

const valueOrDefault = (value, defaultValue) => {
    if ( value == undefined ) {
        return defaultValue;
    }
    return value;
};

const SESSION_STORAGE_OPEN_MENU_ITEM = "uniNav.openMenuItem";

class MenuList extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        openItem: valueOrDefault(sessionStorage.getItem(SESSION_STORAGE_OPEN_MENU_ITEM), ''),
        collapseAll: true
      };

      this.onToggleMenu = this.onToggleMenu.bind(this);
    }

    onToggleMenu(t){        
        if(t.props.menuOpen === true) {
            sessionStorage.setItem(SESSION_STORAGE_OPEN_MENU_ITEM, '');
            this.setState({ menuOpen: false, openItem: '' });
        } else {
            sessionStorage.setItem(SESSION_STORAGE_OPEN_MENU_ITEM, t.props.item.name);
            this.setState({ openItem: t.props.item.name });
        }
    }

    render() {
        if(this.props.menu){
            const listItems = this.props.menu.map((item, idx) =>
                <MenuItem onMenuToggle={this.onToggleMenu} drawerOpen={this.props.drawerOpen} menuOpen={item.name === this.state.openItem ? true : false} item={item} key={idx}></MenuItem>
            );
            return (
                <ul className="top-level-nav">
                    {listItems}
                </ul>
            );
        }
        return null;
    }
}

MenuList.propTypes = {
    menu: PropTypes.array.isRequired,
    drawerOpen: PropTypes.bool.isRequired
};

export default MenuList;
