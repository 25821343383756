import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';

import SubMenuList from './SubMenuList';

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
});

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      menuOpen: false,
      prop: props,
      anchorEl: null,
      subMenu: false,
      contextMenuOpen: false
    };

    this.handleMenuToggle = this.handleMenuToggle.bind(this);
    this.handleOpenNewTab = this.handleOpenNewTab.bind(this);
    this.handleShowContextMenu = this.handleShowContextMenu.bind(this);
    this.handleContextMenuClose = this.handleContextMenuClose.bind(this);
  }


  handleMenuToggle(e) {
    e.preventDefault();
    this.props.onMenuToggle(this);
  }

  handleNavigation() {
    let url = window.location;
    if (this.props.item.fullRoute) {

      if (this.props.forceNewTab || this.props.item.forceNewTab) {
        window.open(this.props.item.fullRoute, '_blank');
        return;
      }

      const urlIdentifier = url.protocol + "//" + url.host + "/" + url.href.split("/")[3];
      const config = window.uniNavConfig.read();
      if (config.appType === 'MVC') {
        window.history.pushState({ 'ajax': '/' + this.props.item.relativeRoute }, this.props.item.title, '/' + this.props.item.relativeRoute);
        let target = document.querySelector('#uniNavLink');
        target.setAttribute('href', '/' + this.props.item.relativeRoute);
        target.click();
        return;
      }

      if ((this.props.item.fullRoute.indexOf(urlIdentifier) > -1) === true) {

        // angular 7+
        if (config.appType === 'NG7') {
          let routeDef = this.props.item.relativeRoute;
          if (routeDef.indexOf('/', 0) === -1) {
            routeDef = '/' + routeDef;
          }
          window.angularComponentRef.zone.run(() => {
            window.angularComponentRef.routerInterface(routeDef);
          });
        }
        if (config.appType === 'NG8') {
          let routeDef = this.props.item.relativeRoute;
          if (routeDef.indexOf('/', 0) === -1) {
            routeDef = '/' + routeDef;
          }
          window.angularComponentRef.zone.run(() => {
            window.angularComponentRef.routerInterface(routeDef);
          });
          return;
        }
        // aurelia v+
        if (config.appType === 'AureliaV1') {
          window.aureliaRouter.navigate(this.props.item.relativeRoute);
          return;
        }

        window.location = this.props.item.fullRoute;
      }
      else {
        window.location = this.props.item.fullRoute;
      }
    }

    if (this.props.handleClose) {
      this.props.handleClose();
    }
  }

  handleOpenNewTab() {
    //window.open(url, '_blank');
  }

  handleShowContextMenu(event) {
    event.preventDefault();
    this.setState({ contextMenuOpen: true });
  }

  handleContextMenuClose() {
    this.setState({ contextMenuOpen: false });
  }

  componentDidMount() {
    /*        let sessionState = JSON.parse(sessionStorage.getItem('leftNavSession'));
             if(sessionState && sessionState.item.name === this.props.item.name) {
              this.setState({ openItem: sessionState.item.name });
              this.props.onMenuToggle(this);
            } */
  }

  render() {
    if (this.props.drawerOpen == false && this.props.item.children != undefined && this.props.item.children.length > 0) {
      const { anchorEl } = this.state;
      const open = Boolean(anchorEl);
      return (
        <div className={this.props.drawerOpen ? 'drawer-open' : 'drawer-closed'}>
          <ListItem id={this.props.item.name} button
            aria-owns={open ? 'collaped-menu' : undefined}
            aria-haspopup="true"
            variant="contained"
            className="flyout" >
            <ListItemIcon >
              <i className={this.props.item.settings.iconClass != "" ? this.props.item.settings.iconClass : this.props.subItem ? "fa fa-chevron-right unify" : ""}></i>
            </ListItemIcon>
            <div className="flyout-wrapper">
              <div className="flyout-content">
                <SubMenuList handleClose={this.props.handleClose} subMenu={this.props.item.children}></SubMenuList>
              </div>
            </div>
          </ListItem>
        </div>
      )
    }
    if (this.props.drawerOpen == false) {
      const { anchorEl } = this.state;
      const open = Boolean(anchorEl);
      return (
        <div className={this.props.drawerOpen ? 'drawer-open' : 'drawer-closed'}>
          <ListItem id={this.props.item.name} button onClick={(e) => this.handleNavigation(e)}
            aria-owns={open ? 'collaped-menu' : undefined}
            aria-haspopup="true"
            variant="contained" >
            <ListItemIcon>
              <i className={this.props.item.settings.iconClass != "" ? this.props.item.settings.iconClass : this.props.subItem ? "fa fa-chevron-right unify" : ""}></i>
            </ListItemIcon>
          </ListItem>
        </div>
      )
    }
    if (this.props.item.children != undefined && this.props.item.children.length > 0) {
      return (
        <div>
          <ListItem id={this.props.item.name} button onClick={this.handleMenuToggle}>
            <ListItemIcon>
              <i className={this.props.item.settings.iconClass}></i>
            </ListItemIcon>
            <ListItemText className="uniNav-menu-title" inset primary={this.props.item.title} />
            {this.props.menuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse className="uniNav-collapse" in={this.props.menuOpen} timeout="auto" unmountOnExit>
            <SubMenuList handleClose={this.props.handleClose} subMenu={this.props.item.children}></SubMenuList>
          </Collapse>
        </div>
      );
    }
    return (
      <ListItem id={this.props.item.name} button onClick={(e) => this.handleNavigation(e)}>
        <ListItemIcon>
          <i className={this.props.item.settings.iconClass != "" ? this.props.item.settings.iconClass : this.props.subItem ? "fa fa-chevron-right unify" : ""}></i>
        </ListItemIcon>
        <ListItemText className="uniNav-menu-title" primary={this.props.item.title} onContextMenu={this.handleShowContextMenu} />
      </ListItem>

    )
  }
}

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  subItem: PropTypes.string,
  subMenu: PropTypes.bool.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  drawerOpen: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  configActions: PropTypes.object.isRequired,
  configData: PropTypes.object.isRequired,
  contextMenuOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  forceNewTab: PropTypes.bool
};

export default withStyles(styles)(MenuItem);
