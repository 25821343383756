/* eslint-disable import/default */
import 'react-app-polyfill/ie9';
import React from 'react';
import { render } from 'react-dom';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
import './styles/styles.scss'; 


require('./favicon.ico'); // Tell webpack to load favicon.ico
require('./version.json'); // Tell webpack to load favicon.ico

const store = configureStore();

render(
  <Root store={store} history={history} ref={(uniNav) => {window.uniNav = uniNav}}/>,
  document.getElementById('uniNavRoot') // TODO: change back to uniNavRoot
);