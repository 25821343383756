import * as types from '../constants/actionTypes';

export function create(data) {
    return (dispatch, getState) => {
        const { roles, config } = getState();
        dispatch({
            type: types.CREATE_LEFT_NAV,
            data: data,
            roles: roles,
            config: config
        });
      };
}

export function update(data) {
    return (dispatch, getState) => {
        const { roles } = getState();
        dispatch({
            type: types.UPDATE_LEFT_NAV,
            data: data,
            roles: roles
        });
      };
}

export function remove(data) {
    return { type: types.DELETE_LEFT_NAV, data };
}

