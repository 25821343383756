// constants

// roles
export const ADD_ROLES = "ADD_ROLES";

// config
export const ADD_CONFIG = "ADD_CONFIG";
export const READ_CONFIG = "READ_CONFIG";
export const UPDATE_CONFIG = "UPDATE_CONFIG";

// left nav
export const CREATE_LEFT_NAV = "CREATE_LEFT_NAV";
export const UPDATE_LEFT_NAV = "UPDATE_LEFT_NAV";
export const DELETE_LEFT_NAV = "DELETE_LEFT_NAV";

// user menu
export const CREATE_USER_MENU = "CREATE_USER_MENU";
export const UPDATE_USER_MENU = "UPDATE_USER_MENU";
export const DELETE_USER_MENU = "DELETE_USER_MENU";

// admin menu
export const CREATE_ADMIN_MENU = "CREATE_ADMIN_MENU";
export const UPDATE_ADMIN_MENU = "UPDATE_ADMIN_MENU";
export const DELETE_ADMIN_MENU = "DELETE_ADMIN_MENU";

// help menu
export const CREATE_HELP_MENU = "CREATE_HELP_MENU";
export const UPDATE_HELP_MENU = "UPDATE_HELP_MENU";
export const DELETE_HELP_MENU = "DELETE_HELP_MENU";

//  whats new menu
export const CREATE_WHATS_NEW_MENU = "CREATE_WHATS_NEW_MENU";
export const UPDATE_WHATS_NEW_MENU = "UPDATE_WHATS_NEW_MENU";
export const DELETE_WHATS_NEW_MENU = "DELETE_WHATS_NEW_MENU";
