import { combineReducers } from 'redux';
import leftNav from './leftNavReducer';
import adminMenu from './adminMenuReducer';
import userMenu from './userMenuReducer';
import helpMenu from './helpMenuReducer';
import roles from './rolesReducer';
import config from './configReducer';
import whatsNew from './whatsNewReducer';
import { connectRouter } from 'connected-react-router'

export default (history) => combineReducers({
  router: connectRouter(history),
  leftNav,
  adminMenu,
  userMenu,
  helpMenu,
  roles,
  config,
  whatsNew
});