import PropTypes from "prop-types";
import React from "react";
import Main from "./Main";
import { withStyles } from '@material-ui/core/styles';

const styles = { 
  progress: {
    margin: 15,
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);
    }

  render()  {
    return(
      <div className="nav-container">
        <div className="site-title"></div>
          <Main></Main>
      </div>
    )
  }
}

App.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(App);
