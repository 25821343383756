/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LeftNav from "./leftNav/LeftNav";
import TopNav from "./topNav/TopNav";
import packageJson from "../../package.json";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as configActions from "../actions/configActions";
import * as roleActions from "../actions/roleActions";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
  },
  content: {
    flexGrow: 1
  },
});

const SESSION_STORAGE_UNINAV_IS_OPEN = "uniNav.isOpen";
const getIsOpen = (defaultValue) => {
  const value = sessionStorage.getItem(SESSION_STORAGE_UNINAV_IS_OPEN);

  if (value != undefined) {
    return value == "true";
  }

  return defaultValue;
};

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: getIsOpen(false),
      infoDrawerOpen: false,
      roleData: Object.assign([], props.roleData),
      configData: Object.assign({}, props.configData),
      appReady: false,
    };
    this.defaultLogo =
      "https://cdn.keefegp.com/assets/logos/KEEFE_logo.png";
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleLeftNavMouseOver = this.handleLeftNavMouseOver.bind(this);
    this.handleLeftNavMouseLeave = this.handleLeftNavMouseLeave.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.info("UI-" + packageJson.version);
    document.addEventListener(
      "DOMContentLoaded",
      function () {
        document
          .getElementById("main-content")
          .appendChild(document.getElementById("uniNavContent"));
      },
      false
    );

    this.setState({
      infoDrawerOpen: true,
    });

    const that = this;
    global.uniNavConfig = {
      create(data) {
        that.props.configActions.create(data);
        let openState = !data.leftNavCollapsed;

        if (sessionStorage.getItem(SESSION_STORAGE_UNINAV_IS_OPEN) === null) {
          sessionStorage.setItem(SESSION_STORAGE_UNINAV_IS_OPEN, openState);
        } else {
          openState =
            sessionStorage.getItem(SESSION_STORAGE_UNINAV_IS_OPEN) === "true";
        }

        that.setState({
          configData: Object.assign({}, data),
          open: openState,
          brandImg:
            data.brand && data.brand.imageUrl
              ? data.brand.imageUrl
              : "https://cdn.keefegp.com/assets/logos/KEEFE_logo.png",
        });
      },

      read() {
        return that.state.configData;
      },

      update(data) {
        that.props.configActions.update(data);
      },

      setReady() {
        that.setState({
          appReady: true,
        });
      },

      setPlaceholderDiv(target, source) {
        document.addEventListener(
          "DOMContentLoaded",
          function () {
            document
              .getElementById(target)
              .appendChild(document.getElementById(source));
          },
          false
        );
      },
    };

    // roles
    global.uniNavRoles = {
      create(data) {
        that.props.roleActions.create(data);
        that.setState({
          roleData: Object.assign([], data),
        });
      },

      read() {
        return that.state.roleData;
      },
    };
  }

  handleDrawerOpen() {
    this.updateLeftNavState(true);
  }

  handleDrawerClose() {
    this.updateLeftNavState(false);
  }

  updateLeftNavState(open) {
    let configState = this.state.configData;
    configState.leftNavCollapsed = !open;
    this.props.configActions.create(configState);

    sessionStorage.setItem(SESSION_STORAGE_UNINAV_IS_OPEN, open);

    this.setState({
      configData: Object.assign({}, configState),
      open: open,
    });
  }

  handleLeftNavMouseOver() {
    if (this.state.configData.leftNavCollapsed) {
      // this.setState({ open: true });
    }
  }

  handleLeftNavMouseLeave() {
    if (this.state.configData.leftNavCollapsed) {
      //  this.setState({ open: false });
    }
  }

  // eslint-disable-next-line no-unused-vars
  toggleInfoDrawer(open) {
    this.setState({ infoDrawerOpen: open });
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>        
        <AppBar
          position="fixed"
          color="inherit"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <TopNav className="uniNav-top-nav"></TopNav>
          </Toolbar>
        </AppBar>
        <Drawer
          id="unifyDrawer"
          variant="permanent"
          className={classNames(
            classes.drawer,
            {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            },
            "envLabel"
          )}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          onMouseOver={this.handleLeftNavMouseOver}
          onMouseLeave={this.handleLeftNavMouseLeave}
          open={this.state.open}
        >
          <div id="unifiyLogoContainer" className={classes.toolbar}>
            <div className="logo-div">
              <img
                alt="Keefe Group Logo"
                width="136px"
                height="43px"
                onClick={this.handleDrawerOpen}
                src={this.state.brandImg}
              />
            </div>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <LeftNav drawerOpen={this.state.open}></LeftNav>
        </Drawer>
        <main id="main-content" className={classes.content}>
          <div className={classes.toolbar} />
        </main>
      </div>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  configActions: PropTypes.object.isRequired,
  configData: PropTypes.object.isRequired,
  roleActions: PropTypes.object.isRequired,
  roleData: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    configData: state.configData,
    roleData: state.roleData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    configActions: bindActionCreators(configActions, dispatch),
    roleActions: bindActionCreators(roleActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Main));
