/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MenuList from "../shared/MenuList";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/leftNavActions";

class LeftNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      leftNav: Object.assign([], props.data),
    };
  }

  // component left nav api
  componentDidMount() {
    const that = this;
    global.uniNavLeft = {
      create(data) {
        that.props.actions.create(data);
        that.setState({
          leftNav: Object.assign([], data),
        });
      },

      read() {
        return that.props.data;
      },

      update(data) {
        that.props.actions.update(data);
      },

      delete(data) {
        that.props.actions.remove(data);
        that.setState(that.state);
      },
    };
  }

  render() {
    return (
      <div id="leftNavigation" className="left-navigation">
        <MenuList
          menu={this.state.leftNav}
          drawerOpen={this.props.drawerOpen}
        ></MenuList>
      </div>
    );
  }
}

LeftNav.propTypes = {
  data: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  drawerOpen: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    data: state.leftNav,
    roles: state.roles,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
