import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function configReducer(state = initialState.config, action) {
  switch (action.type) {
    case types.ADD_CONFIG:
      return action.data;
    case types.UPDATE_CONFIG:
      return Object.assign(state, action.data);
    default:
      return state;
  }
}
