import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function whatsNewReducer(state = initialState.whatsNew, action) {
    var index;
    switch (action.type) {
        case types.CREATE_WHATS_NEW_MENU:
        if(action.config.useRoles){
          action.data.forEach(item => {
            if(!item.authRoles.some(r=> action.roles.indexOf(r) >= 0)){
              var index = action.data.indexOf(item);
              if (index !== -1){
                action.data.splice(index, 1);
              }
            }
          });
      }
    return action.data.map(i => ({
      ...i
    })); 

    case types.UPDATE_WHATS_NEW_MENU:
      if(state.length) {
        index = state.findIndex((x) => x.name === action.data.name);
        if(index > 0){
          return [...state.filter(item => item.name !== action.data.name), {
            ...state[index],
            ...action.data
          }];
        }
      }
      return state;
    case types.DELETE_WHATS_NEW_MENU:
      return state.filter(item => item.name !== action.data.name);
    default:
      return state;
    }
}