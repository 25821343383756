/* eslint-disable no-undef */
import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/userMenuActions';
import MenuItem from '../shared/MenuItem';

class UserMenu extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      openItem: '',
      collapseAll: true
    };

    this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  onToggleMenu(t){
    if(t.props.menuOpen === true) {
        this.setState({ menuOpen: false, openItem: '' });
    }
    else {
        this.setState({ openItem: t.props.item.name });
    }
  }

  render() {
    if(this.props.data){
      const listItems = this.props.data.map((item, idx) =>
        <MenuItem handleClose={this.props.handleClose} onMenuToggle={this.onToggleMenu} menuOpen={item.name === this.state.openItem ? true : false} item={item} key={idx}></MenuItem>
      );
    return (
    <div>
        {listItems}
    </div>
    );
  }
  return null;
  }
}

UserMenu.propTypes = {
  data: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
};

function mapStateToProps(state){

  return {
    data: state.userMenu,
  }
}

function mapDispatchToProps(dispatch){
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
