 export default {
  config: {
    appType: "",
    leftNavCollapsed: "",
    isQA: false,
    rolesEnabled: true,
    ready: false
  },
  left : [],
  admin: [],
  help: [],
  user: [],
  roles: [],
  whatsNew: []
};
/*
export default {
  config: {
    appType: "NG7",
    leftNavCollapsed: false,
    rolesEnabled: false,
  },
  roles: ["test"],
  left: [ {
      "name": "Home",
      "title": "Home",
      "routeSequence": 1,
      "relativeRoute": "commissary/home",
      "fullRoute": "$(baseUrl)/commissary/home",
      "moduleId": "components/home/home",
      "nav": true,
      "auth": true,
      "authRoles": [
        "all"
      ],
      "routable": false,
      "crumbs": [],
      "settings": {
        "iconClass": "fa fa-home",
        "iconHtml": "<i class='fa fa-home' aria-hidden='true'></i>",
        "childSelector": ""
      },
      "children": []
    },
    {
      "name": "OffenderNav",
      "title": "$(Resident)",
      "relativeRoute": "Banking/1.4/TrustAccount/OffenderManage",
      "fullRoute": "$(baseUrl)/Banking/1.4/TrustAccount/OffenderManage",
      "routeSequence": 2,
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "ACCESS-BANKING",
        "OFFENDER-ACCESS"
      ],
      "routable": false,
      "settings": {
        "iconClass": "fa fa-user-o ",
        "iconHtml": "<i class='fa fa-user-o' aria-hidden='true'></i>",
        "childSelector": "offender-sub-nav"
      },
      "children": [{
          "name": "OffenderManageOffender",
          "title": "Manage $(Resident)",
          "routeSequence": 1,
          "relativeRoute": "Banking/1.4/TrustAccount/OffenderManage",
          "fullRoute": "$(baseUrl)/Banking/1.4/TrustAccount/OffenderManage",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "OffenderBookOffender",
          "title": "Book $(Resident)",
          "routeSequence": 2,
          "relativeRoute": "Banking/1.4/Search/BookingSearch",
          "fullRoute": "$(baseUrl)/Banking/1.4/Search/BookingSearch",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-BOOK"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "OffenderModifyOffender",
          "title": "Modify $(Resident)",
          "routeSequence": 3,
          "relativeRoute": "Banking/1.4/TrustAccount/Edit",
          "fullRoute": "$(baseUrl)/Banking/1.4/TrustAccount/Edit",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-MODIFY"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "OffenderReleaseOffender",
          "title": "Release $(Resident)",
          "routeSequence": 4,
          "relativeRoute": "Banking/1.4/Release/Close",
          "fullRoute": "$(baseUrl)/Banking/1.4/Release/Close",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-RELEASE"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "OffenderReports",
          "title": "Reports",
          "routeSequence": 5,
          "relativeRoute": "Banking/1.4/Report/Index/OffenderBanking",
          "fullRoute": "$(baseUrl)/Banking/1.4/Report/Index/OffenderBanking",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        }
      ]
    },
    {
      "name": "BankingNav",
      "title": "Banking",
      "relativeRoute": "Banking",
      "fullRoute": "$(baseUrl)/Banking",
      "routeSequence": 3,
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "ACCESS-BANKING",
        "BANKING-ACCESS"
      ],
      "routable": false,
      "settings": {
        "iconClass": "fa fa-university",
        "iconHtml": "<i class='fa fa-university' aria-hidden='true'></i>",
        "childSelector": "banking-sub-nav"
      },
      "children": [{
          "name": "BankingTransactionBatches",
          "title": "Transaction Batches",
          "routeSequence": 1,
          "relativeRoute": "Banking/1.4/Batch/Index/1",
          "fullRoute": "$(baseUrl)/Banking/1.4/Batch/Index/1",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-BATCH"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "BankingCashDrawers",
          "title": "Cash Drawers",
          "routeSequence": 2,
          "relativeRoute": "Banking/1.4/CashDrawer/List/1",
          "fullRoute": "$(baseUrl)/Banking/1.4/CashDrawer/List/1",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "DRAWER-MANAGER-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "BankingGeneralLedger",
          "title": "General Ledger",
          "routeSequence": 3,
          "relativeRoute": "Banking/1.4/GL/Accounts",
          "fullRoute": "$(baseUrl)/Banking/1.4/GL/Accounts",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "GL-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "BankingAccountsPayable",
          "title": "Accounts Payable",
          "routeSequence": 4,
          "relativeRoute": "Banking/1.4/Payables/List",
          "fullRoute": "$(baseUrl)/Banking/1.4/Payables/List",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "GL-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "BankingChecks",
          "title": "Checks",
          "routeSequence": 5,
          "relativeRoute": "Banking/1.4/BankPrintCheck",
          "fullRoute": "$(baseUrl)/Banking/1.4/BankPrintCheck",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "BANK-MANAGER-PRINT-CHECKS",
            "GL-ACCESS",
            "BANK-MANAGER-EXPORT"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "BankingBankReconciliation",
          "title": "Bank Reconciliation",
          "routeSequence": 6,
          "relativeRoute": "Banking/1.4/BankReconciliation/Index",
          "fullRoute": "$(baseUrl)/Banking/1.4/BankReconciliation/Index",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "BANK-MANAGER-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "BankingSearchTransactions",
          "title": "Search Transactions",
          "routeSequence": 7,
          "relativeRoute": "Banking/1.4/BankRegister",
          "fullRoute": "$(baseUrl)/Banking/1.4/BankRegister",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "BANK-MANAGER-SEARCH"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "BankingReports",
          "title": "Reports",
          "routeSequence": 8,
          "relativeRoute": "Banking/1.4/Report/Index/Reports",
          "fullRoute": "$(baseUrl)/Banking/1.4/Report/Index/Reports",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "REPORTS-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        }
      ]
    },
    {
      "name": "my-queue",
      "title": "My Queue",
      "relativeRoute": "Banking/1.4/TaskRequest/Index",
      "fullRoute": "$(baseUrl)/Banking/1.4/TaskRequest/Index",
      "routeSequence": 4,
      "moduleId": "Banking/TaskRequest/Index",
      "nav": true,
      "auth": true,
      "authRoles": [
        "ACCESS-BANKING",
        "OFFENDER-ACCESS",
        "BANKING-ACCESS"
      ],
      "routable": true,
      "settings": {
        "iconClass": "fa fa-sort-amount-desc",
        "iconHtml": "<i class='fa fa-sort-amount-desc' aria-hidden='true'></i>",
        "childSelector": ""
      },
      "children": []
    },
    {
      "name": "Commissary",
      "title": "Commissary",
      "routeSequence": 5,
      "relativeRoute": "commissary/commissary",
      "fullRoute": "$(baseUrl)/commissary",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "COMMISSARY-ACCESS"
      ],
      "routable": false,
      "crumbs": [],
      "settings": {
        "iconClass": "fa fa-shopping-cart",
        "iconHtml": "<i class='fa fa-shopping-cart' aria-hidden='true'></i>",
        "childSelector": "commissary-sub-nav-1"
      },
      "children": [{
          "name": "Orders",
          "title": "Orders",
          "routeSequence": 1,
          "relativeRoute": "commissary/commissary/orders/view-orders",
          "fullRoute": "$(baseUrl)/commissary/orders/view-orders",
          "moduleId": "components/commissary/orders/view-orders",
          "nav": true,
          "auth": true,
          "authRoles": [
            "COMMISSARY-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "Refunds",
          "title": "Refunds",
          "routeSequence": 2,
          "relativeRoute": "commissary/commissary/manage/view-refunds",
          "fullRoute": "$(baseUrl)/commissary/manage/view-refunds",
          "moduleId": "components/commissary/manage/view-refunds",
          "nav": true,
          "auth": true,
          "authRoles": [
            "COMMISSARY-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "Catalog",
          "title": "Catalogs",
          "routeSequence": 3,
          "relativeRoute": "commissary/commissary/catalog",
          "fullRoute": "$(baseUrl)/commissary/catalog",
          "moduleId": "components/commissary/catalog",
          "nav": true,
          "auth": true,
          "authRoles": [
            "COMMISSARY-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "Batches",
          "title": "Batches",
          "routeSequence": 4,
          "relativeRoute": "commissary/commissary/batches/view-batches",
          "fullRoute": "$(baseUrl)/commissary/batches/view-batches",
          "moduleId": "components/commissary/batches/view-batches",
          "nav": true,
          "auth": true,
          "authRoles": [
            "COMMISSARY-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        }
      ]
    },
    {
      "name": "TEX",
      "title": "Communications",
      "routeSequence": 6,
      "relativeRoute": "tex/fusion/thread/list",
      "fullRoute": "$(baseUrl)/tex/fusion/thread/list",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "ACCESS-OCC"
      ],
      "routable": false,
      "crumbs": [],
      "settings": {
        "iconClass": "fa fa-comments-o",
        "iconHtml": "<i class='fa fa-comments-o' aria-hidden='true'></i>",
        "childSelector": "tex-sub-nav-1"
      },
      "children": [{
          "name": "TEX-Conversations",
          "title": "Conversations",
          "routeSequence": 1,
          "relativeRoute": "tex/fusion/thread/list",
          "fullRoute": "$(baseUrl)/tex/fusion/thread/list",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-OCC"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          },
          "children": null
        },
        {
          "name": "TEX-Reports",
          "title": "Reports",
          "routeSequence": 2,
          "relativeRoute": "tex/fusion/report/list",
          "fullRoute": "$(baseUrl)/tex/fusion/report/list",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-OCC"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          },
          "children": null
        },
        {
          "name": "TEX-Classic",
          "title": "Classic Edge Exchange",
          "routeSequence": 3,
          "relativeRoute": "thread/list",
          "fullRoute": "$(baseUrl)/tex/thread/list",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-OCC"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          },
          "children": null
        }
      ]
    },
    {
      "name": "access-corrections-agency-manager",
      "title": "Access Corrections",
      "relativeRoute": "",
      "fullRoute": "$(amBaseUrl)/app",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "AC-AGENCY-REPORTS-ACCESS"
      ],
      "routable": false,
      "settings": {
        "iconClass": "fa fa-usd",
        "iconHtml": "<i class='fa fa-usd' aria-hidden='true'></i>",
        "childSelector": "am-sub-nav-1"
      },
      "children": [{
          "name": "Reports",
          "title": "Reports",
          "routeSequence": "",
          "relativeRoute": "",
          "fullRoute": "",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "AC-AGENCY-REPORTS-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "fa fa-bar-chart",
            "iconHtml": "<i class='fa fa-bar-chart' aria-hidden='true'></i>",
            "childSelector": ""
          },
          "children": [{
              "name": "BankTransfer",
              "title": "Bank Transfers",
              "routeSequence": "",
              "relativeRoute": "",
              "fullRoute": "/app/report/ach-batch-summary/KCN-34004P001-DPR?r=true",
              "moduleId": "",
              "nav": true,
              "auth": true,
              "authRoles": [
                "AC-AGENCY-REPORTS-ACCESS"
              ],
              "routable": true,
              "crumbs": [],
              "settings": {
                "iconClass": "",
                "iconHtml": "",
                "childSelector": ""
              },
              "children": null
            },
            {
              "name": "CashPickups",
              "title": "Cash Pickups",
              "routeSequence": "",
              "relativeRoute": "",
              "fullRoute": "",
              "moduleId": "",
              "nav": true,
              "auth": true,
              "authRoles": [
                "AC-AGENCY-REPORTS-ACCESS"
              ],
              "routable": true,
              "crumbs": [],
              "settings": {
                "iconClass": "",
                "iconHtml": "",
                "childSelector": ""
              },
              "children": null
            },
            {
              "name": "Restriction",
              "title": "Restriction",
              "routeSequence": "",
              "relativeRoute": "",
              "fullRoute": "",
              "moduleId": "",
              "nav": true,
              "auth": true,
              "authRoles": [
                "AC-AGENCY-REPORTS-ACCESS"
              ],
              "routable": true,
              "crumbs": [],
              "settings": {
                "iconClass": "",
                "iconHtml": "",
                "childSelector": ""
              },
              "children": null
            },
            {
              "name": "RecipientActivity",
              "title": "Recipient Activity",
              "routeSequence": "",
              "relativeRoute": "",
              "fullRoute": "",
              "moduleId": "",
              "nav": true,
              "auth": true,
              "authRoles": [
                "AC-AGENCY-REPORTS-ACCESS"
              ],
              "routable": true,
              "crumbs": [],
              "settings": {
                "iconClass": "",
                "iconHtml": "",
                "childSelector": ""
              },
              "children": null
            },
            {
              "name": "DepositorActivity",
              "title": "Depositor Activity",
              "routeSequence": "",
              "relativeRoute": "",
              "fullRoute": "",
              "moduleId": "",
              "nav": true,
              "auth": true,
              "authRoles": [
                "AC-AGENCY-REPORTS-ACCESS"
              ],
              "routable": true,
              "crumbs": [],
              "settings": {
                "iconClass": "",
                "iconHtml": "",
                "childSelector": ""
              },
              "children": null
            },
            {
              "name": "Audit",
              "title": "Audit",
              "routeSequence": "",
              "relativeRoute": "",
              "fullRoute": "",
              "moduleId": "",
              "nav": true,
              "auth": true,
              "authRoles": [
                "AC-AGENCY-REPORTS-ACCESS"
              ],
              "routable": true,
              "crumbs": [],
              "settings": {
                "iconClass": "",
                "iconHtml": "",
                "childSelector": ""
              },
              "children": null
            }
          ]
        },
        {
          "name": "DataDetective",
          "title": "Date Detective",
          "routeSequence": "",
          "relativeRoute": "",
          "fullRoute": "",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "AC-AGENCY-REPORTS-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "fa fa-fingerprint",
            "iconHtml": "<i class='fa fa-fingerprint' aria-hidden='true'></i>",
            "childSelector": ""
          },
          "children": []
        }
      ]
    }
  ],
  admin: [{
      "name": "Global",
      "title": "Global",
      "routeSequence": 1,
      "relativeRoute": "commissary/admin",
      "fullRoute": "$(baseUrl)/commissary/admin",
      "moduleId": "components/admin/admin",
      "nav": true,
      "auth": true,
      "authRoles": [
        "FACILITY-PORTAL-ADMIN"
      ],
      "routable": true,
      "crumbs": [],
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": []
    },
    {
      "name": "AdminOffender",
      "title": "$(Resident)",
      "routeSequence": 2,
      "relativeRoute": "Banking/1.4/TrustAccount/OffenderManage",
      "fullRoute": "$(baseUrl)/Banking/1.4/TrustAccount/OffenderManage",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "ACCESS-BANKING",
        "OFFENDER-ACCESS"
      ],
      "routable": false,
      "crumbs": [],
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [{
          "name": "AdminOffenderGeneral",
          "title": "General",
          "routeSequence": 1,
          "relativeRoute": "Banking/1.4/Workflow/OffenderAdminAgency",
          "fullRoute": "$(baseUrl)/Banking/1.4/Workflow/OffenderAdminAgency",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ADMIN-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminManageOffender",
          "title": "Manage $(Resident)",
          "routeSequence": 2,
          "relativeRoute": "Banking/1.4/Workflow/Manage",
          "fullRoute": "$(baseUrl)/Banking/1.4/Workflow/Manage",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBookOffender",
          "title": "Book $(Resident)",
          "routeSequence": 3,
          "relativeRoute": "Banking/1.4/Workflow/BookOffender",
          "fullRoute": "$(baseUrl)/Banking/1.4/Workflow/BookOffender",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-BOOK"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminModifyOffender",
          "title": "Modify $(Resident)",
          "routeSequence": 4,
          "relativeRoute": "Banking/1.4/Workflow/ModifyOffenderLockList",
          "fullRoute": "$(baseUrl)/Banking/1.4/Workflow/ModifyOffenderLockList",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-MODIFY"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminReleaseOffender",
          "title": "Release $(Resident)",
          "routeSequence": 5,
          "relativeRoute": "Banking/1.4/Workflow/ReleaseOffenderIndex",
          "fullRoute": "$(baseUrl)/Banking/1.4/Workflow/ReleaseOffenderIndex",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-RELEASE"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminOffenderReports",
          "title": "Reports",
          "routeSequence": 6,
          "relativeRoute": "Banking/1.4/Workflow/Reports",
          "fullRoute": "$(baseUrl)/Banking/1.4/Workflow/Reports",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        }
      ]
    },
    {
      "name": "AdminBankingNav",
      "title": "Banking",
      "relativeRoute": "Banking",
      "fullRoute": "$(baseUrl)/Banking",
      "routeSequence": 3,
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "ACCESS-BANKING",
        "BANKING-ACCESS"
      ],
      "routable": false,
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [{
          "name": "AdminBankingGlobal",
          "title": "Global",
          "routeSequence": 1,
          "relativeRoute": "Banking/1.4/Workflow/HomeAgency",
          "fullRoute": "$(baseUrl)/Banking/1.4/Workflow/HomeAgency",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ADMIN-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          },
          "children": null
        },
        {
          "name": "AdminBankingGeneral",
          "title": "General",
          "routeSequence": 1,
          "relativeRoute": "Banking/1.4/Workflow/AdminAgency",
          "fullRoute": "$(baseUrl)/Banking/1.4/Workflow/AdminAgency",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ADMIN-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBankingAccounts",
          "title": "Accounts",
          "routeSequence": 2,
          "relativeRoute": "Banking/1.4/Account/List",
          "fullRoute": "$(baseUrl)/Banking/1.4/Account/List",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ADMIN-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBankingAccountingEvents",
          "title": "Accounting Events",
          "routeSequence": 3,
          "relativeRoute": "Banking/1.4/AccountingEvent",
          "fullRoute": "$(baseUrl)/Banking/1.4/AccountingEvent",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ADMIN-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBankingTransactionBatches",
          "title": "Transaction Batches",
          "routeSequence": 4,
          "relativeRoute": "Banking/1.4/Batch/AdminAgencyTransactionBatchSettings",
          "fullRoute": "$(baseUrl)/Banking/1.4/Batch/AdminAgencyTransactionBatchSettings",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "OFFENDER-BATCH"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBankingCashDrawers",
          "title": "Cash Drawers",
          "routeSequence": 5,
          "relativeRoute": "Banking/1.4/CashDrawerType",
          "fullRoute": "$(baseUrl)/Banking/1.4/CashDrawerType",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "DRAWER-MANAGER-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBankingGeneralLedger",
          "title": "General Ledger",
          "routeSequence": 6,
          "relativeRoute": "Banking/1.4/Workflow/GeneralLedger",
          "fullRoute": "$(baseUrl)/Banking/1.4/Workflow/GeneralLedger",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "GL-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBankingChecks",
          "title": "Checks",
          "routeSequence": 7,
          "relativeRoute": "Banking/1.4/CheckConfiguration",
          "fullRoute": "$(baseUrl)/Banking/1.4/CheckConfiguration",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "BANK-MANAGER-PRINT-CHECKS",
            "GL-ACCESS",
            "BANK-MANAGER-EXPORT"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBankingReports",
          "title": "Reports",
          "routeSequence": 8,
          "relativeRoute": "Banking/1.4/Report/Administration",
          "fullRoute": "$(baseUrl)/Banking/1.4/Report/Administration",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ACCESS-BANKING",
            "REPORTS-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBankingImportExport",
          "title": "Import & Export",
          "routeSequence": 9,
          "relativeRoute": "Banking/1.4/ImportExport",
          "fullRoute": "$(baseUrl)/Banking/1.4/ImportExport",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ADMIN-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminBankingSystemIntegration",
          "title": "System Integration",
          "routeSequence": 9,
          "relativeRoute": "Banking/1.4/SystemIntegration",
          "fullRoute": "$(baseUrl)/Banking/1.4/SystemIntegration",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "ADMIN-ACCESS"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        }
      ]
    },
    {
      "name": "AdminCommissary",
      "title": "Commissary",
      "relativeRoute": "commissary",
      "fullRoute": "$(baseUrl)/commissary",
      "routeSequence": 4,
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "COMMISSARY-ADMIN"
      ],
      "routable": false,
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [{
          "name": "AdminCommissaryGeneral",
          "title": "General",
          "routeSequence": 1,
          "relativeRoute": "commissary/admin/comm-agency",
          "fullRoute": "$(baseUrl)/commissary/admin/comm-agency",
          "moduleId": "components/admin/comm-agency",
          "nav": true,
          "auth": true,
          "authRoles": [
            "COMMISSARY-ADMIN"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminCommissaryCatalog",
          "title": "Catalogs",
          "routeSequence": 2,
          "relativeRoute": "commissary/admin/catalog-config",
          "fullRoute": "$(baseUrl)/commissary/admin/catalog-config",
          "moduleId": "components/admin/catalog-config/delivery-locations/list",
          "nav": true,
          "auth": true,
          "authRoles": [
            "COMMISSARY-ADMIN"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        },
        {
          "name": "AdminCommissaryOrderRestrictions",
          "title": "Rules",
          "routeSequence": 3,
          "relativeRoute": "commissary/admin/restrictions/order-restrictions",
          "fullRoute": "$(baseUrl)/commissary/admin/restrictions/order-restrictions",
          "moduleId": "components/admin/restrictions/order-restrictions/index",
          "nav": true,
          "auth": true,
          "authRoles": [
            "COMMISSARY-ADMIN"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          }
        }
      ]
    },
    {
      "name": "AdminCommunications",
      "title": "Communications",
      "relativeRoute": "tex/fusion/thread/list",
      "fullRoute": "$(baseUrl)/tex/fusion/thread/list",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "ACCESS-OCC"
      ],
      "routable": false,
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [{
          "name": "TEX-Topics",
          "title": "Topics",
          "routeSequence": 1,
          "relativeRoute": "tex/fusion/topics/list",
          "fullRoute": "$(baseUrl)/tex/fusion/topics/list",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "TOPICS-MANAGE"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          },
          "children": null
        },
        {
          "name": "TEX-EmailGroups",
          "title": "Email Groups",
          "routeSequence": 2,
          "relativeRoute": "tex/fusion/emailgroups/list",
          "fullRoute": "$(baseUrl)/tex/fusion/emailgroups/list",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "TOPICS-MANAGE"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          },
          "children": null
        },
        {
          "name": "TEX-Responses",
          "title": "Responses",
          "routeSequence": 2,
          "relativeRoute": "tex/fusion/responses",
          "fullRoute": "$(baseUrl)/tex/fusion/responses",
          "moduleId": "",
          "nav": true,
          "auth": true,
          "authRoles": [
            "TOPICS-MANAGE"
          ],
          "routable": true,
          "crumbs": [],
          "settings": {
            "iconClass": "",
            "iconHtml": "",
            "childSelector": ""
          },
          "children": null
        }
      ]
    }
  ],
  help: [{
      "name": "HelpBanking",
      "title": "Banking",
      "routeSequence": 1,
      "relativeRoute": "Banking/1.4/Help/v1/index.htm#t=Announcements.htm",
      "fullRoute": "https://khsait.centric.com/Banking/1.4/Help/v1/index.htm#t=Announcements.htm",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "ACCESS-BANKING"
      ],
      "routable": true,
      "crumbs": null,
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [

      ]
    },
    {
      "name": "HelpCommissary",
      "title": "Commissary",
      "routeSequence": 2,
      "relativeRoute": "HelpDocumentation/Commissary/",
      "fullRoute": "https://khsait.centric.com/HelpDocumentation/Commissary/",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "COMMISSARY-ACCESS"
      ],
      "routable": true,
      "crumbs": null,
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [

      ]
    },
    {
      "name": "HelpCommunications",
      "title": "Communications",
      "routeSequence": 3,
      "relativeRoute": "TEX/TEXhelp/v1/Announcements.htm#t=Announcements_1.htm",
      "fullRoute": "https://khsait.centric.com/TEX/TEXhelp/v1/Announcements.htm#t=Announcements_1.htm",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "ACCESS-OCC"
      ],
      "routable": true,
      "crumbs": null,
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [

      ]
    },
    {
      "name": "HelpTermsandConditions",
      "title": "Terms and Conditions",
      "routeSequence": 4,
      "relativeRoute": "Security/Html/Terms.html",
      "fullRoute": "https://khsait.centric.com/Security/Html/Terms.html",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "all"
      ],
      "routable": true,
      "crumbs": null,
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [

      ]
    },
    {
      "name": "HelpPrivacyStatement",
      "title": "Privacy Statement",
      "routeSequence": 5,
      "relativeRoute": "Security/Html/Privacy.html",
      "fullRoute": "https://khsait.centric.com/Security/Html/Privacy.html",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "all"
      ],
      "routable": true,
      "crumbs": null,
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [

      ]
    },
    {
      "name": "HelpContactUs",
      "title": "Contact Us",
      "routeSequence": 6,
      "relativeRoute": "mailto:kcnhelpdesk@keefegroup.com?subject=KHS%20Support%20Request&body=Provide%20the%20following%20info%20and%20any%20other%20info%20you%20feel%20will%20be%20helpful%20in%20resolving%20the%20issue.%0d%0a*%20Customer%3a%0d%0a*%20User%3a%0d%0a*%20Application%3a%0d%0a*%20Screen%20or%20report%3a%0d%0a*%20Error%20log%20number%3a%0d%0a*%20Steps%20leading%20up%20to%20the%20issue%3a%0d%0a*%20Issue%3a%0d%0a*%20Expected%20result%3a",
      "fullRoute": "mailto:kcnhelpdesk@keefegroup.com?subject=KHS%20Support%20Request&body=Provide%20the%20following%20info%20and%20any%20other%20info%20you%20feel%20will%20be%20helpful%20in%20resolving%20the%20issue.%0d%0a*%20Customer%3a%0d%0a*%20User%3a%0d%0a*%20Application%3a%0d%0a*%20Screen%20or%20report%3a%0d%0a*%20Error%20log%20number%3a%0d%0a*%20Steps%20leading%20up%20to%20the%20issue%3a%0d%0a*%20Issue%3a%0d%0a*%20Expected%20result%3a",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": [
        "all"
      ],
      "routable": true,
      "crumbs": null,
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": [

      ]
    }
  ],
  user: [{
      "name": "SecurityManagement",
      "title": "Security Management",
      "routeSequence": 1,
      "relativeRoute": "SecurityManagement",
      "fullRoute": "$(baseUrl)/SecurityManagement",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": ["all"],
      "routable": true,
      "crumbs": [],
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": []
    },
    {
      "name": "LogOff",
      "title": "Log Off",
      "routeSequence": 2,
      "relativeRoute": "Security/identity/logout",
      "fullRoute": "$(baseUrl)/Security/identity/logout",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": ["all"],
      "routable": true,
      "crumbs": [],
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": []
    },
    {
      "name": "Station",
      "title": "Station: $(station)",
      "routeSequence": 3,
      "relativeRoute": "",
      "fullRoute": "",
      "moduleId": "",
      "nav": true,
      "auth": true,
      "authRoles": ["all"],
      "routable": true,
      "crumbs": [],
      "settings": {
        "iconClass": "",
        "iconHtml": "",
        "childSelector": ""
      },
      "children": []
    }
  ]
};
 */
