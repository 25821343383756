/* eslint-disable no-undef */
import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/userMenuActions';
import MenuItem from '../shared/MenuItem';



class WhatsNewMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newItemCount: 0,
            items: []
        };
    }


    render() {
        if (this.props.data) {
            const listItems = this.props.data.map((item, idx) =>
                <MenuItem
                    handleClose={this.props.handleClose}
                    onMenuToggle={this.onToggleMenu}
                    menuOpen={item.name === this.state.openItem ? true : false}
                    item={item}
                    forceNewTab={true}
                    key={idx}></MenuItem>
            );
            return (
                <div>
                    {listItems}
                </div>
            );
        }
        return null;
    }
}


function mapStateToProps(state) {
    return {
        data: state.whatsNew
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

WhatsNewMenu.propTypes = {
    data: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNewMenu);