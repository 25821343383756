import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function leftNavReducer(state = initialState.left, action) {
  var index;
  switch (action.type) {
    case types.CREATE_LEFT_NAV:
        if(action.config.useRoles){
          action.data.forEach(item => {
              item.children.forEach(child => {
                if(!child.authRoles.some(r=> action.roles.indexOf(r) >= 0)){
                  var index = item.children.indexOf(child);
                  if (index !== -1){
                    item.children.splice(index, 1);
                  }
                }
              });
              if(!item.authRoles.some(r=> action.roles.indexOf(r) >= 0)){
                var index = action.data.indexOf(item);
                if (index !== -1){
                  action.data.splice(index, 1);
                }
              }
            });
        }
      return action.data;
    case types.UPDATE_LEFT_NAV:
      if(state.length) {
        index = state.findIndex((x) => x.name === action.data.name);
        if(index > 0 && action.data.authRoles.some(r=>action.roles.indexOf(r) >= 0)){
          action.data.children.forEach(child => {
            if(!child.authRoles.some(r=> action.roles.indexOf(r) >= 0)){
              var index = action.data.children.indexOf(child);
              if (index !== -1){
                action.data.children.splice(index, 1);
              }
            }
          });
          return [...state.filter(item => item.name !== action.data.name), Object.assign(state[index], action.data)];
        }
      }
      return state;
    case types.DELETE_LEFT_NAV:
      return state.filter(item => item.name !== action.data.name);
    default:
      return state;
  }
}
