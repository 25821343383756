import * as types from '../constants/actionTypes';

export function create(data) {
    return { type: types.ADD_CONFIG, data };
}

export function read() {
    return (dispatch, getState) => {
        const { config } = getState();
        dispatch({
            type: types.READ_CONFIG,
            config: config
        });
      };
}


export function update(data) {
    return { type: types.UPDATE_CONFIG, data };
}