import * as types from '../constants/actionTypes';

export function create(data) {
    return (dispatch, getState) => {
        const { roles, config } = getState();
        dispatch({
            type: types.CREATE_HELP_MENU,
            data: data,
            roles: roles,
            config: config
        });
      };
}

export function update(data) {
    return { type: types.UPDATE_HELP_MENU, data };
}

export function remove() {
    return { type: types.DELETE_HELP_MENU };
}


