import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function rolesReducer(state = initialState.roles, action) {
  switch (action.type) {
    case types.ADD_ROLES:
      action.data.push('all');
      return action.data;
    default:
      return state;
  }
}
