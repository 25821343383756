import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function userMenuReducer(state = initialState.user, action) {
  var index;
  switch (action.type) {
    case types.CREATE_USER_MENU:
        if(action.config.useRoles){
          action.data.forEach(item => {
            if(!item.authRoles.some(r=> action.roles.indexOf(r) >= 0)){
              var index = action.data.indexOf(item);
              if (index !== -1){
                action.data.splice(index, 1);
              }
            }
          });
      }
    return action.data; //.sort((a) => (a.name === 'LogOff') ? 1 : -1);
    case types.UPDATE_USER_MENU:
    if(state.length) {
      index = state.findIndex((x) => x.name === action.data.name);
      if(index > 0){
        return [...state.filter(item => item.name !== action.data.name), Object.assign(state[index], action.data)];
      }
    }
    return state;
    case types.DELETE_USER_MENU:
      return state.filter(item => item.name !== action.data.name);
    default:
      return state;
  }
}
